import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IProspect } from "../../contracts/data/IProspect";
import { Formik } from "formik";
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import AlertAutoDismissible from "../shared/AlertAutoDismissible";
import {
  IInsurance,
  InsuranceExpectedNetRate,
  InsurancePaymentInterval,
  InsuranceType,
} from "../../contracts/data/IInsurance";
import FormikCheckbox from "../shared/FormikCheckbox";
import * as Yup from "yup";
import FormikInput from "../shared/FormikInput";
import { TUpdateProspectAction } from "../../redux/actions/prospect";
import FormikSicknessPayDeductibleDaysPicker from "../shared/FormikSicknessPayDeductibleDaysPicker";
import FormikSlider from "../shared/FormikSlider";
import FormikSicknessDurationPicker from "../shared/FormikSicknessDurationPicker";
import FormikInsurancePaymentIntervalPicker from "../shared/FormikInsurancePaymentIntervalPicker";
import DiscountDropdown from "../shared/DiscountDropdown";
import { getPercentString } from "../../utils/appUtils";
import FormikExpectedNetRatePicker from "../shared/FormikExpectedNetRatePicker";
import { IPromptMessage } from "../../contracts/data/IPromptMessage";

type TProspectEditInsuranceFormValues = {
  isVisible: boolean;
  isTotalsVisible: boolean;
  annualIncomeYel: number | null;
  optionalSicknessPayDeductibleDays: number;
  optionalSicknessPayAmount: number | null;
  optionalSicknessDuration: number;
  optionalDisabilityPensionAmount: number | null;
  optionalLifeInsuranceAmount: number | null;
  optionalSeriousIllnessInsuranceAmount: number;
  optionalExpectedNetRate: number;
  optionalOldAgeExtraPensionAnnualCost: number;
  optionalOldAgeExtraPensionStartAge: number | null;
  optionalOldAgeExtraPensionEndAge: number | null;
  paymentInterval: number;
};

type TProspectEditInsuranceFormProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  insurance: IInsurance;
  updateProspect: TUpdateProspectAction;
  promptMessages: IPromptMessage[];
  promptMessageHasChangeWithNull(): boolean;
  handelUnSavedChanges(): Promise<void>;
} & WithTranslation;

type TProspectEditInsuranceFormState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};

const AnnualYelIncomeLowerLimit: number = 9209;
const AnnualYelIncomeUpperLimit: number = 209125;
const defaultSicknessDuration = 730;
const defaultDeductibleDays = 9;
const possibleLifeInsuranceDiscount: number = 20;

class ProspectEditInsuranceForm extends Component<TProspectEditInsuranceFormProps, TProspectEditInsuranceFormState> {
  constructor(props: TProspectEditInsuranceFormProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
    };
  }

  render() {
    const { t, prospect, insurance } = this.props;

    const isInsuranceOfType = (types: InsuranceType[]) => {
      return types.findIndex((t) => t === insurance.type) > -1;
    };
    const hasChangeWithNull = this.props.promptMessageHasChangeWithNull();

    const initialValues: TProspectEditInsuranceFormValues = {
      isVisible: insurance.isVisible,
      isTotalsVisible: insurance.isTotalsVisible,
      annualIncomeYel: insurance.annualIncomeYel,
      optionalSicknessPayDeductibleDays:
        insurance.optionalSicknessPayDeductibleDays > 0
          ? insurance.optionalSicknessPayDeductibleDays
          : defaultDeductibleDays,
      optionalSicknessDuration:
        insurance.optionalSicknessDuration > 0 ? insurance.optionalSicknessDuration : defaultSicknessDuration,
      optionalSicknessPayAmount: insurance.optionalSicknessPayAmount,
      optionalDisabilityPensionAmount: insurance.optionalDisabilityPensionAmount,
      optionalLifeInsuranceAmount: insurance.optionalLifeInsuranceAmount,
      optionalSeriousIllnessInsuranceAmount: insurance.optionalSeriousIllnessInsuranceAmount,
      optionalExpectedNetRate:
        insurance.optionalExpectedNetRate > 0
          ? insurance.optionalExpectedNetRate
          : InsuranceExpectedNetRate.zeroPercent,
      optionalOldAgeExtraPensionAnnualCost: insurance.optionalOldAgeExtraPensionAnnualCost,
      optionalOldAgeExtraPensionStartAge: insurance.optionalOldAgeExtraPensionStartAge ?? null,
      optionalOldAgeExtraPensionEndAge: insurance.optionalOldAgeExtraPensionEndAge ?? null,
      paymentInterval: insurance.paymentInterval ?? InsurancePaymentInterval.OneTime,
    };

    const validationSchema = Yup.object().shape({
      isVisible: Yup.boolean().required(t("Required")),
      annualIncomeYel: Yup.lazy(() => {
        if (isInsuranceOfType([InsuranceType.Alternative])) {
          return Yup.number()
            .nullable()
            .min(AnnualYelIncomeLowerLimit, t("Value too low"))
            .max(AnnualYelIncomeUpperLimit, t("Value too high"));
        } else if (isInsuranceOfType([InsuranceType.Omsen])) {
          return Yup.number().notRequired();
        } else {
          return Yup.number()
            .required(t("Required"))
            .min(AnnualYelIncomeLowerLimit, t("Value too low"))
            .max(AnnualYelIncomeUpperLimit, t("Value too high"));
        }
      }),
      optionalSicknessPayDeductibleDays: Yup.number().required(t("Required")).min(0, t("Must be positive")),
      optionalSicknessPayAmount: Yup.number().nullable().min(0, t("Must be positive")),
      optionalSicknessDuration: Yup.number().required(t("Required")).min(0, t("Must be positive")),
      optionalDisabilityPensionAmount: Yup.number().nullable().min(0, t("Must be positive")),
      optionalLifeInsuranceAmount: Yup.number().nullable().min(0, t("Must be positive")),
      optionalSeriousIllnessInsuranceAmount: Yup.number()
        .required(t("Required"))
        .min(0, t("Must be positive"))
        .max(100000, t("Value too high")),
      optionalExpectedNetRate: Yup.number().required(t("Required")).min(0, t("Must be positive")),
      optionalOldAgeExtraPensionAnnualCost: Yup.number().required(t("Required")).min(0, t("Must be positive")),
      optionalOldAgeExtraPensionStartAge: Yup.number().notRequired().min(55, "Must be greater than or equal to 55"),
      optionalOldAgeExtraPensionEndAge: Yup.number().notRequired().min(57, "Must be greater than or equal to 57"),
      paymentInterval: Yup.number().required(t("Required")),
    });

    const yelTitle = t("YEL income");

    const LifeInsuranceDiscountDropdownAsUnit = (
      <DiscountDropdown
        unit={"€"}
        handleDiscount={(discount: number) =>
          this.props.updateProspect(this.props.prospect.id, { optionalLifeInsuranceDiscount: discount })
        }
        initialDiscount={this.props.prospect.optionalLifeInsuranceDiscount}
        possibleDiscount={possibleLifeInsuranceDiscount}
      />
    );

    const appendDiscountText =
      this.props.prospect.optionalLifeInsuranceDiscount && this.props.prospect.optionalLifeInsuranceDiscount > 0
        ? " -" + getPercentString(this.props.prospect.optionalLifeInsuranceDiscount)
        : null;

    const renderSubmitButton = (): JSX.Element => {
      return (
        <Button
          variant="primary"
          type="submit"
          className={"w-100"}
          disabled={this.props.isSubmitting}
        >
          {this.props.isSubmitting ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
            </>
          ) : null}
          {t("Update")}
        </Button>
      );
    };
    const renderAnnualIncomeYelInput = () => {
      if (!isInsuranceOfType([InsuranceType.Omsen])) {
        return (
          <div className={"FormikInput"}>
            <div className={"form-group"}>
              <Form.Label>{yelTitle}</Form.Label>
              <div className={"input-group"}>
                <Form.Control
                  name={"annualIncomeYel"}
                  type="number"
                  value={this.props.prospect.annualIncomeYel ?? 0}
                  readOnly={true}
                  className={"is-readonly"}
                />
                <span className="input-group-text">{`€/${t("year_unit")}`}</span>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          if (!hasChangeWithNull) await this.props.handelUnSavedChanges();

          let optionalLifeInsurance: any = values.optionalLifeInsuranceAmount;
          if (optionalLifeInsurance === "") {
            // We need to send the value as null to the backend, so that it is parsed correctly
            optionalLifeInsurance = null;
            // Used to trigger a rerender, to get the latest values from the backend.
            values.optionalLifeInsuranceAmount = initialValues.optionalLifeInsuranceAmount;
          }

          let optionalDisabilityInsurance: any = values.optionalDisabilityPensionAmount;
          if (optionalDisabilityInsurance === "") {
            optionalDisabilityInsurance = null;
            values.optionalDisabilityPensionAmount = initialValues.optionalDisabilityPensionAmount;
          }

          let optionalSicknessPayAmount: any = values.optionalSicknessPayAmount;
          if (optionalSicknessPayAmount === "") {
            optionalSicknessPayAmount = null;
            values.optionalSicknessPayAmount = initialValues.optionalSicknessPayAmount;
          }

          let optionalExpectedRateAmount: any = values.optionalExpectedNetRate;
          if (optionalExpectedRateAmount === "") {
            optionalExpectedRateAmount = null;
            values.optionalExpectedNetRate = initialValues.optionalExpectedNetRate;
          }

          let annualIncomeYel: any = values.annualIncomeYel;
          if (annualIncomeYel === "") {
            // We need to send the value as null to the backend, so that it is parsed correctly
            annualIncomeYel = null;

            // Used to trigger a rerender, to get the latest values from the backend.
            values.annualIncomeYel = initialValues.annualIncomeYel;
          }

          // if OldAgeExtraPension start or end age is empty set values null that it calculated with default age value
          const optionalOldAgeExtraPensionStartAge: any = values.optionalOldAgeExtraPensionStartAge;
          const optionalOldAgeExtraPensionEndAge: any = values.optionalOldAgeExtraPensionEndAge;
          if(optionalOldAgeExtraPensionStartAge === "" || optionalOldAgeExtraPensionEndAge === "") {
            values.optionalOldAgeExtraPensionStartAge = null;
            values.optionalOldAgeExtraPensionEndAge = null;
          }
          const insurances = prospect.insurances.slice().map((i) => {
            if (i.id === insurance.id) {
              return {
                ...i,
                ...values,
                optionalLifeInsuranceAmount: optionalLifeInsurance,
                optionalDisabilityPensionAmount: optionalDisabilityInsurance,
                annualIncomeYel: annualIncomeYel,
                optionalSicknessPayAmount: optionalSicknessPayAmount,
                optionalExpectedNetRate: values.optionalExpectedNetRate,
              };
            }
            // Currently we let the payment interval specified for the offer insurance affect all insurances
            return { ...i, paymentInterval: values.paymentInterval };
          });
          const result = await this.props.updateProspect(prospect.id, { insurances });

          if (result !== null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: true,
              submitResultMessage: t("Successfully updated"),
            }));
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to update"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            {isInsuranceOfType([InsuranceType.Offer, InsuranceType.Alternative, InsuranceType.Comparison]) ? (
              <FormikSlider
                name={"annualIncomeYel"}
                title={yelTitle}
                onChange={formikProps.handleChange}
                onFinish={formikProps.handleSubmit}
                min={9209}
                max={209125}
                type="string"
                unit={`€/${t("year_unit")}`}
              />
            ) : (
              renderAnnualIncomeYelInput()
            )}
            {isInsuranceOfType([InsuranceType.Offer]) ? (
              <>
                <FormikInsurancePaymentIntervalPicker name={"paymentInterval"} title={t("Payment interval")} />
                <Row>
                  <Col xs={12} className={"form-group mb-0"}>
                    <Form.Label>{t("Sickness optional compensation")}</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <FormikSicknessPayDeductibleDaysPicker name={"optionalSicknessPayDeductibleDays"} />
                  </Col>
                  <Col xs={8}>
                    <FormikInput name={"optionalSicknessPayAmount"} type={"number"} unit={"€"} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={"form-group mb-0"}>
                    <Form.Label>{t("Sickness optional duration")}</Form.Label>
                  </Col>
                  <Col xs={12}>
                    <FormikSicknessDurationPicker name={"optionalSicknessDuration"} />
                  </Col>
                </Row>
                <FormikInput
                  name={"optionalDisabilityPensionAmount"}
                  title={t("Optional disability pension")}
                  type={"number"}
                  unit={"€"}
                />
                <FormikInput
                  name={"optionalLifeInsuranceAmount"}
                  title={
                    <>
                      {t("Optional life insurance")}
                      <span className="applied-discount-text">{appendDiscountText}</span>
                    </>
                  }
                  type={"number"}
                  unit={LifeInsuranceDiscountDropdownAsUnit}
                />
                <FormikInput
                  name={"optionalSeriousIllnessInsuranceAmount"}
                  title={t("Serious illness one-off payment")}
                  type={"number"}
                  unit={"€"}
                />
                <FormikExpectedNetRatePicker title={t("Expected net rate")} name={"optionalExpectedNetRate"} />
                <FormikInput
                  name={"optionalOldAgeExtraPensionAnnualCost"}
                  title={t("Old age pension extra") + " (" + t("Annual cost").toLowerCase() + ")"}
                  type={"number"}
                  unit={`€/${t("year_unit")}`}
                />
                {formikProps.values.optionalOldAgeExtraPensionAnnualCost > 0 && (
                  <Row>
                    <Col>
                      <FormikInput
                        name="optionalOldAgeExtraPensionStartAge"
                        title={t("Pension start age")}
                        type="number"
                        min={55}
                      />
                    </Col>
                    <Col xs={1} className="d-flex justify-content-center align-items-center mt-3 fw-bold">—</Col>
                    <Col>
                      <FormikInput
                        name="optionalOldAgeExtraPensionEndAge"
                        title={t("Pension end age")}
                        type="number"
                        min={57}
                      />
                    </Col>
                  </Row>
                )}
              </>
            ) : null}
            <FormikCheckbox name={"isVisible"} title={t("Visible")} />

            {isInsuranceOfType([InsuranceType.Offer]) && formikProps.values.isVisible && (
              <FormikCheckbox name={"isTotalsVisible"} title={t("Totals visible")} />
            )}

            <Form.Group className="form-group">
              {renderSubmitButton()}
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(ProspectEditInsuranceForm);
